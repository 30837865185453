export default {
    my_bills: 'My bills',
	add: 'Add',
	successfully_added: 'Bill was successfully added',
    bill_number: 'Bill number',
    reference: 'Reference',
    date_of_service: 'Date of service',
    date_expires: 'Date of expires',
    price: 'Net price',
    tax: 'Tax',
    final_price: "Gross price",
    add_new_bill: "Add new bill",
    issuer: "Issuer",
    select_customer: "Select customer",
    payment_method: "Payment method",
    select_payment_method: "Select payment option",
    customer: "Customer",
    dont_put_customer_on_bill: "Bill without customer",
    dont_put_payment_method_on_bill: "Don't put payment method on bill",
    dates: "Select dates",
    date_of_issue: "Date/time of issue",
    date_of_expiration: "Date of expiration",
    dont_put_date_of_service_on_bill: "Don't put date of service on bill",
    no_date_of_expiration: "Don't put date of expiration on bill",
    fiscally_confirm_bill: "Fiscally confirm bill",
    skus: "Products / services",
    sku: "Product",
    qty: "Quantity",
    unit: "Unit",
    discount: "Discount %",
    tax_percent: "Tax %",
    total: "Total",
    to_pay: "To pay",
    create_new_bill: "Create new bill",
    view_bill: "View bill",
    status: "Status",
    created_at: "Created at",
    storno: "Storno bill",
    cancel: "Cancel",
    you_are_going_to_storno: "You are going to storno bill",
    are_you_sure: "Are you sure?",
    select_logo: "Select logo",
    please_correct_errors: 'Please correct all errors.',
    appendices: 'Appendices',
    custom_service: "Custom",
    fiscally_verification_explanation: 'Fiscally verifying bill is not enabled until you add the certificate in the settings and set abbreviations for the business premises and electronic device (similar to what you have in the mini cash register).',
    tax_amount: 'Total tax',
    final_net_price: 'Final net price',
    issued: 'Issued',
    storno_b: 'Storno',
    storno_bill: 'Storno bill',
    final_bill_price: 'Final price',
	email_was_sent: "Email was successfully sent.",
	email_was_not_sent: "Email could not be sent. Please try again later.",
	send_bill_to_email: "Send bill to email",
	send_bill_to_customer: "Send bill to customer",
}
