const routes = [
	{
		path: '/',
		name: 'home',
		meta: {
			requires_auth: true
		},
		component: () => import('layouts/MainLayout.vue'),
		children: [

			// OAUTH
			{
				path: 'prijava',
				name: 'login',
				component: () => import('pages/OAuth/Login.vue'),
				meta: {
                    requires_auth: false,
					go_home_if_logged_in: true,
				}
			},
			{
				path: 'registracija',
				name: 'register',
				component: () => import('pages/OAuth/Register'),
				meta: {
                    requires_auth: false,
                    go_home_if_logged_in: true,
				}
			},
			{
				path: 'zakljuci-registracijo',
				name: 'complete-registration',
				component: () => import('pages/OAuth/CompleteRegistration'),
				meta: {
                    requires_auth: false,
                    go_home_if_logged_in: true,
				}
			},
			{
				path: 'potrdi-registracijo',
				name: 'confirm-registration',
				component: () => import('pages/OAuth/ConfirmRegistration'),
				meta: {
                    requires_auth: false,
                    go_home_if_logged_in: true,
				}
			},
			{
				path: 'ponastavitev-gesla',
				name: 'reset-password',
				component: () => import('pages/OAuth/ResetPasswordEmail'),
				meta: {
                    requires_auth: false,
                    go_home_if_logged_in: true,
				}
			},
			{
				path: 'spremeni-geslo',
				name: 'reset-password-new',
				component: () => import('pages/OAuth/ResetPasswordNew'),
				meta: {
                    requires_auth: false,
                    go_home_if_logged_in: false,
				}
			},
			// OAUTH

			// BILLS
			{
				path: 'racuni',
				name: 'bills',
				component: () => import('pages/Bills/Bills'),
				meta: {
                    requires_auth: true
				}
			},
			{
				path: 'racuni/nov-racun',
				name: 'new-bill',
				component: () => import('pages/Bills/Add'),
				meta: {
                    requires_auth: true
				}
			},
			{
				path: 'racuni/pregled-racuna/:_id',
				name: 'view-bill',
                component: () => import('pages/Bills/View'),
				meta: {
                    requires_auth: true
				}
			},
			// BILLS

			// CUSTOMERS
			{
				path: 'stranke',
				name: 'customers',
				component: () => import('pages/Customers/Customers'),
				meta: {
                    requires_auth: true,
				},
			},
			{
				path: 'stranke/nova-stranka',
				name: 'add-customer',
				component: () => import('pages/Customers/AddEdit'),
			},
			{
				path: 'stranke/uredi-stranko/:_id',
				name: 'edit-customer',
				component: () => import('pages/Customers/AddEdit'),
			},
			// CUSTOMERS

			// SERVICES/ SKUS
			{
				path: 'produkti',
				name: 'skus',
				component: () => import('pages/Skus/Skus'),
				meta: {
                    requires_auth: true,
				},
			},
			{
				path: 'produkti/nov-produkt',
				name: 'add-sku',
				component: () => import('pages/Skus/AddEdit'),
			},
			{
				path: 'produkti/produkt/:id',
				name: 'edit-sku',
				component: () => import('pages/Skus/AddEdit'),
			},
            // SERVICES/ SKUS

			// STATISTICS
			{
				path: 'statistika',
				name: 'statistics',
				component: () => import('pages/Statistics/View'),
				meta: {
                    requires_auth: true,
				},
			},
            // STATISTICS

			// STATISTICS
			{
				path: 'izvoz-podatkov',
				name: 'exports',
				component: () => import('pages/Exports/View'),
				meta: {
                    requires_auth: true,
				},
			},
            // STATISTICS

			// SETTINGS
			{
				path: 'nastavitve',
				name: 'settings',
				component: () => import('pages/Settings/Settings'),
				meta: {
                    requires_auth: true
				}
			},
			// SETTINGS


		]
	},

	// Always leave this as the last one,
	// but you can also remove it
	{
		path: '/:catchAll(.*)*',
		component: () => import('pages/Error404.vue')
	}
]

export default routes
