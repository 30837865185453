export default {
	my_bills: 'Moji računi',
	add: 'Nov',
    successfully_added: 'Račun je bil uspešno dodan',
    bill_number: 'Št. računa',
    reference: 'Referenca / sklic',
    date_of_service: 'Datum storitev',
    date_expires: 'Datum zapadlosti',
    price: 'Cena brez davka',
    tax: 'Davek',
    final_price: "Cena z davkom",
    add_new_bill: "Nov račun",
    issuer: "Izdajatelj",
    select_customer: "Izberi stranko",
    payment_method: "Plačilna metoda",
    select_payment_method: "Izbira plačilne metode",
    customer: "Stranka",
    dont_put_customer_on_bill: "Račun brez stranke",
    dont_put_payment_method_on_bill: "Na račun ne izpiši plačilne metode",
    dates: "Izibira datumov",
    date_of_issue: "Datum/ćas izdaje",
    date_of_expiration: "Datum zapadlosti",
    dont_put_date_of_service_on_bill: "Ne izpiši datuma storitve",
    no_date_of_expiration: "Ne izpiši datuma zapadlosti",
    fiscally_confirm_bill: "Davčno potrdi račun",
    skus: "Produkti / storitve",
    sku: "Produkt",
    custom_service: "Po meri",
    qty: "Količina",
    unit: "Enota",
    discount: "Popust %",
    tax_percent: "Davek %",
    total: "Skupaj",
    to_pay: "Za plačilo",
    create_new_bill: "Ustvari nov račun",
    view_bill: "Pregled računa",
    status: "Status",
    created_at: "Datum",
    storno: "Storniraj",
    cancel: "Preklici",
    you_are_going_to_storno: "Stornirali boste račun",
    are_you_sure: "Ali ste prepričani?",
    select_logo: "Izbira logotipa",
    please_correct_errors: 'Prosimo odpravite napake.',
    appendices: 'Priloga',
    fiscally_verification_explanation: 'Davčno potrjevanje bo mogoče šele takrat, ko v nastavitvah dodate certifikat, določite kratice za poslovni prostor in elektronsko napravo, enako kot ste to storili pri mini blagajni.',
    tax_amount: 'Skupaj davek',
    final_net_price: 'Končna neto cena',
    issued: 'Izdan',
    storno_b: 'Storniran',
    storno_bill: 'Storno račun',
    final_bill_price: 'Končna cena',
	email_was_sent: "E-pošta je bila uspešno poslana.",
	email_was_not_sent: "Prišlo je do napake. Prosimo poskusite kasneje.",
	send_bill_to_email: "Pošlji račun",
	send_bill_to_customer: "Pošlji račun stranki",
}
