export default {
    my_customers: 'Moje stranke',
    add_customer: 'Dodaj novo stranko',
    update_customer: 'Posodobi stranko',
	name: 'Ime',
	last_name: 'Priimek',
	company: 'Podjetje',
	add: 'Dodaj',
	edit: 'Uredi',
	update: 'Posodobi',
	delete: 'Odstrani',
	are_you_sure: 'Ali ste prepričani?',
    you_are_going_to_delete: 'Ali ste prepričani, da želite odstraniti stranko',
	cancel: 'Prekliči',
    successfully_deleted: 'Stranka je bila uspešno odstranjena.',
    success_updated: 'Stranka je bila uspešno posodobljena.',
    success_added: 'Stranka je bila uspešno dodana.',
    please_correct_errors: 'Prosimo, odpravite napake.',
}
