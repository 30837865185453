import {boot} from 'quasar/wrappers'
import axios from 'axios'

// Be careful when using SSR for cross-request state pollution
// due to creating a Singleton instance here;
// If any client changes this (global) instance, it might be a
// good idea to move this instance creation inside of the
// "export default () => {}" function below (which runs individually
// for each client)

function getApiUrl() {

    const hostname = window.location.hostname;

    if (hostname.includes('stage.moj-racun.si')) {
        return 'https://stage.moj-racun.si/api';
    }
    if (hostname.includes('moj-racun.si')) {
        return 'https://moj-racun.si/api';
    }

    return 'http://0.0.0.0:3050/api';
}

const api = axios.create({baseURL: getApiUrl()})

export default boot(({app, store, router}) => {
	// for use inside Vue files (Options API) through this.$axios and this.$api

	app.config.globalProperties.$axios = axios
	// ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
	//       so you won't necessarily have to import axios in each vue file

	app.config.globalProperties.$api = api
	// ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
	//       so you can easily perform requests against your app's API


	// Request interceptors
	api.interceptors.request.use(async req => {

		// Add JWT token to request headers
		const token = store.getters['user/jwtToken'];
		if (token) {
			req.headers.Authorization = `Bearer ${token}`
		}

		return req
	})

	// response interceptor
	api.interceptors.response.use(res => {

			// Refresh JWT TOKEN if data.jwt_token present in response
			if (res.headers.refresh_token) {
				store.dispatch('user/setJwtToken', {jwt_token: res.headers.refresh_token})
			}

			return res
		},
		err => {

			if (err.response.status === 401) {
				store.dispatch('user/logout')
				router.push({name: 'login'})
			}

            if (!err.response.jwt_decode_fail) {
                store.dispatch('user/logout')
                router.push({name: 'login'})
            }

			// return Error object with Promise
			return Promise.reject(err);
		});

})

export {api}
