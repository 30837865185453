export default {
	register: 'Registracija',
	company: 'Ime podjetja',
	name: 'Ime',
    last_name: 'Priimek',
	iban: 'TRR',
    registration_number: 'Matična številka',
	company_type: 'Vrsta podjetja',
	veisVatNotValid: 'Davčna številka ni pravilna',
	register_button: 'Potrdi registracijo',
    update_button: 'Posodobi',
    example_iban: 'Primer: SI56 0649 6783 3657 647',
	example_register_number: 'Primer: 1232409000',
	register_number_length: 'Register number must be exactly 10 numbers long',
    registration_success: 'Uspešno ste se registrirali',
	update_success: 'Uspešno ste posodobili podatke',
    taxable: 'Davčni zavezanec',
    a_typical_taxable: 'Atipični davčni zavezanec',
    user_already_exists_please_login_or_reset_password: 'Uporabnik že obstaja. Prosimo, prijavite se ali ponastavite geslo.',
}
