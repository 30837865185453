import { boot } from 'quasar/wrappers'
import {date} from "quasar";

/**
 * Validate email
 * @param email
 * @returns {boolean}
 */
const validateEmail = (email) => {

	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(String(email).toLowerCase());
};

/**
 * Validate password
 * @param password
 * @returns {*}
 */
const validatePassword = (password) => {

    const passMatch = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,35}$/;
    return password.match(passMatch)
}

/**
 * Format date
 * @param value
 * @returns {*|string}
 */
const formatDate = (value) => {
	if (!value || value === '') return '';
	if (!Date.parse(value)) {
		return value;
	}
	return date.formatDate(value, 'DD.MM.YYYY');
};

/**
 * Format date time
 * @param value
 * @returns {*|string}
 */
const formatDateTime = (value) => {
	if (!value || value === '') return '';
	if (!Date.parse(value)) {
		return value;
	}
	return date.formatDate(value, 'DD.MM.YYYY HH:mm');
};

/**
 * Format date time with seconds
 * @param value
 * @returns {*|string}
 */
const formatDateTimeSeconds = (value) => {
	if (!value || value === '') return '';
	if (!Date.parse(value)) {
		return value;
	}
	return date.formatDate(value, 'DD.MM.YYYY HH:mm:ss');
};

export default boot(({ app }) => {

	app.config.globalProperties.$validateEmail = validateEmail
	app.provide('validateEmail', validateEmail);

	app.config.globalProperties.$formatDate = formatDate
	app.provide('formatDate', formatDate);

	app.config.globalProperties.$formatDateTime = formatDateTime
	app.provide('formatDateTime', formatDateTime);

	app.config.globalProperties.$formatDateTimeSeconds = formatDateTimeSeconds
	app.provide('formatDateTimeSeconds', formatDateTimeSeconds);

	app.config.globalProperties.$validatePassword = validatePassword
	app.provide('validatePassword', validatePassword);
})

export { validateEmail, formatDate, formatDateTime, validatePassword, formatDateTimeSeconds }



