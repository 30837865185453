import countryService from '../../api-service/country.service';

const state = {

	countries: {},
    countries_array: [],
};

const mutations = {

	setCountries(state, payload) {

		state.countries = payload;
	},
    setCountriesArray(state, payload) {

        state.countries_array = payload;
    },

};

const actions = {

	/**
	 * Countries
	 * @param commit
	 * @param payload
	 * @returns {Promise<*>}
	 */
	async get({commit}, payload = {}) {

		try {

			let response = await countryService.get();

			if (response && response.status === 200) {
                let array = [];
                Object.values(response.data).forEach(v => {
                    array.push(v);
                })
				commit('setCountries', response.data)
				commit('setCountriesArray', array)
			}

			return response;

		} catch (err) {

			return err;
		}
	},

};

const getters = {

	countries: (state) => {

		return state.countries;
	},

    countries_array: (state) => {

		return state.countries_array;
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
