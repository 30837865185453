export default {
    skus: 'Produkti',
	update_sku: 'Uredi produkt',
	add: 'Dodaj',
	edit: 'Uredi',
	update: 'Posodobi',
	delete: 'Odstrani',
	final_price: "Končna cena",
	qty: "Količina",
	discount: "Popust",
    are_you_sure: 'Ali ste prepričani?',
    you_are_going_to_delete: 'Ali ste prepričani, da želite odstraniti ta produkt?',
	cancel: 'Prekliči',
    successfully_deleted: 'Produkt je bil uspešno odstranjen.',
    success_updated: 'Produkt je bila uspešno posodobljen.',
    success_added: 'Produkt je bil uspešno dodan.',
    please_correct_errors: 'Prosimo, odpravite napake.',
    title: 'Ime',
	net: 'Neto',
	gross: 'Bruto',
	unit: 'Enota (kos, ura, ...)',
    net_price: 'Cena na enoto (neto)',
    gross_price: 'Cena na enoto (bruto)',
    tax: 'Davek',
    add_new_product: 'Dodaj nov produkt',
    gross_price_notification: 'Trenutno niste označeni da ste davčni zavezanec (davek se ne obračuna), kar pomeni, da se pri izdaji računov upošteva samo neto cena. Če želite prilagoditi to nastavitev, prosimo, da obiščete razdelek »Nastavitve«, nato pa v meniju »Profil« označite možnost »Davčni zavezanec«.',
}
