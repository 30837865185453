import billService from '../../api-service/bill.service'
import { Loading } from 'quasar'
import axios from "axios"

const state = {

	request_loading: false,
	bill: {},
	bills: [],
};

const mutations = {

    setBill(state, payload) {

        state.bill = payload;
    },


    setBills(state, payload) {

		state.bills = payload;
	},

	setBillsRequestLoading(state, payload) {

		payload ? Loading.show() : Loading.hide();
		state.request_loading = payload;
	},

	clearStore(state){

		state.request_loading = false;
		state.bills = [];
	}

};

const actions = {

	/**
	 * Get bills
	 * @param commit
	 * @param payload
	 * @returns {Promise<*>}
	 */
	async getAll({commit}, payload) {

		try {

			commit('setBillsRequestLoading', true);

			let response = await billService.getAll(payload);

			if (response && response.status === 200) {

				commit('setBills', response.data)
			}

			commit('setBillsRequestLoading', false);

			return response;

		} catch (err) {

			commit('setBillsRequestLoading', false);

			return err;
		}
	},

	/**
	 * Get bills
	 * @param commit
	 * @param payload
	 * @returns {Promise<*>}
	 */
	async get({commit}, payload) {

		try {

			commit('setBillsRequestLoading', true);

			let response = await billService.get(payload);

			if (response && response.status === 200) {

                commit('setBill', response.data)
			}

			commit('setBillsRequestLoading', false);

			return response;

		} catch (err) {

			commit('setBillsRequestLoading', false);

			return err;
		}
	},

	/**
	 * add bills
	 * @param commit
	 * @param payload
	 * @returns {Promise<*>}
	 */
	async insert({commit}, payload) {

		try {

			commit('setBillsRequestLoading', true);

			let response = await billService.insert(payload);

			if (response && response.status === 201) {

				commit('setBill', response.data)
			}

			commit('setBillsRequestLoading', false);

			return response;

		} catch (err) {

			commit('setBillsRequestLoading', false);

			return err;
		}
	},

	/**
	 * Storno bill
	 * @param commit
	 * @param payload
	 * @returns {Promise<*>}
	 */
	async stornoBill({commit}, payload) {

		try {

			commit('setBillsRequestLoading', true);

			let response = await billService.storno(payload);

			if (response && response.status === 201) {

                commit('setBill', response.data)
			}

			commit('setBillsRequestLoading', false);

			return response;

		} catch (err) {

			commit('setBillsRequestLoading', false);

			return err;
		}
	},

	/**
	 * Download bill
	 * @param commit
	 * @param payload
	 * @returns {Promise<*>}
	 */
	async downloadBill({commit}, payload) {

		try {

			commit('setBillsRequestLoading', true);

			let response = await billService.downloadBill(payload);

			if (response && response.status === 200) {

                const download_response = await axios.get(response.data.url, { responseType: 'blob' });
                const blob = new Blob([download_response.data], { type: 'application/pdf' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = response.data.file_name;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
			}

			commit('setBillsRequestLoading', false);

		} catch (err) {

			commit('setBillsRequestLoading', false);

			return err;
		}
	},

	/**
	 * Send a bill to email
	 * @param commit
	 * @param payload
	 * @returns {Promise<*>}
	 */
	async sendBillToEmail({commit}, payload) {

		try {

			commit('setBillsRequestLoading', true);

			let response = await billService.sendBillToEmail(payload);

			commit('setBillsRequestLoading', false);

			return response.data;

		} catch (err) {

			commit('setBillsRequestLoading', false);

			return err;
		}
	}

};

const getters = {

	bill: (state) => {

		return state.bill;
	},

	bills: (state) => {

		return state.bills;
	},

	request_loading: (state) => {

		return state.request_loading;
	},

};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
