import {route} from 'quasar/wrappers'
import {createRouter, createMemoryHistory, createWebHistory, createWebHashHistory} from 'vue-router'
import routes from './routes'
import {Quasar} from 'quasar'
import jwtDecode from "jwt-decode";


/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */
export default route(({store}, Vue) => {

    const createHistory = process.env.SERVER ? createMemoryHistory : (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory)

    const Router = createRouter({
        scrollBehavior: () => ({left: 0, top: 0}),
        routes,

        // Leave this as is and make changes in quasar.conf.js instead!
        // quasar.conf.js -> build -> vueRouterMode
        // quasar.conf.js -> build -> publicPath
        history: createHistory(process.env.MODE === 'ssr' ? void 0 : process.env.VUE_ROUTER_BASE)
    })

    Router.beforeEach((to, from, next) => {

        const is_logged_in = Object.keys(store.getters['user/user']).length > 3;

        if (to.meta.requires_auth) {
            if (!is_logged_in) {
                return next({name: 'login'});
            }
            if (tokenExpired(to)) {
                return next({name: 'login'});
            }
        }

        /*
        if (to.meta.go_home_if_logged_in) {
            if (is_logged_in) {
                return next({name: 'home'});
            }
        }

         */

        next();
    })

    /**
     * Check if jwt token expired. If so, go to logout page
     * @param to
     * @returns {boolean}
     */
    function tokenExpired(to) {
        const token = store.getters['user/jwtToken'];
        if (token) {
            const decoded = jwtDecode(token);
            const currentTime = Math.round(new Date() / 1000);
            if (decoded.exp && currentTime > decoded.exp && to.name !== 'login') {
                store.dispatch('user/logout')
                return true;
            }
        }

        return false;
    }

    const setLocale = async function (locale) {
        try {
            await import(`quasar/lang/${locale}`)
                .then(lang => {
                    Quasar.lang.set(lang.default);
                    Vue.i18n.set(locale);
                })
        } catch (err) {
            console.log("ERRROR changing locale")
            console.log(err.message)
        }
    }


    return Router
})
