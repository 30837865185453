import login from './login'
import register from './register'
import complete_registration from './completeRegistration'
import confirm_registration from './confirmRegistration'
import reset_password_email from './resetPasswordEmail'
import reset_password_new from './resetPasswordNew'
import customers from './customers'
import menu from './menu'
import settings from './settings'
import bills from './bills'
import skus from './skus'
import statistics from './statistics'
import exports from './exports'

export default {
	failed: 'Action failed',
	success: 'Action was successful',
	header_my_bills: 'My bills',
    select_language: 'Select Language',
	button_loading: 'Loading...',
	log_out: 'Logout',
    field_required: 'This field is required.',
    field_must_be_greater_then_zero: 'Field must be greater then 0',
	please_input_valid_email: 'Please input valid email address.',
	only_whole_numbers_allowed: 'Only whole numbers allowed',
    not_registered_yet: 'Not registered? Create an account!',
	forgot_password: 'Forgot password? Reset it here.',
    already_have_account: 'You already have an account? Login here.',
	password: 'Password*',
    repeat_password: 'Repeat password*',
	street: 'Street',
	house_number: 'House nr',
    post_number: 'Postal code',
	city: 'City',
	country: 'Country',
    decimal_hint: 'Decmalna števila se ločijo s piko .',
	email: 'Email',
    vat_id: 'Vat ID.',
    phone_number: 'Mobile nr.',
	passwords_dont_match: 'Passwords don\'t match',
    validate_password: 'Password must be between 7 and 35 characters long.Must contain at least one numeric digit, one uppercase and one lowercase letter',
	example_phone_number: 'Ex.: (+386) 41-123-456',
    field_must_have_four: 'Postal code must be 4 numbers',
	default: 'Default',
    add: 'Add',
    remove: 'Remove',
	update: 'Update',
	delete: 'Delete',
    cancel: 'Cancel',
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
    gross: 'Gross',
    net: 'Net',
    tax: 'Tax',
    year: 'Year',
    month: 'Month',

	login,
	register,
    complete_registration,
    confirm_registration,
    reset_password_email,
    reset_password_new,
	customers,
	menu,
	settings,
	bills,
    skus,
    statistics,
    exports,
}
