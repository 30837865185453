import userService from '../../api-service/user.service'
import {Loading} from 'quasar'

const state = {

    request_loading: false,

    user: {},
    jwtToken: null,
};

const mutations = {

    setUser(state, payload) {

        state.user = payload.user;
        if (payload.jwt_token) {
            state.jwtToken = payload.jwt_token;
        }
    },

    setLogos(state, payload) {

        state.logo = payload;
    },

    setJwtToken(state, payload) {

        state.jwtToken = payload.jwt_token
    },

    setRequestLoading(state, payload) {

        payload ? Loading.show() : Loading.hide();
        state.request_loading = payload;
    },

    clearStore(state) {
        state.request_loading = false;
        state.user = {};
        state.jwtToken = null;
    }

};

const actions = {

    async validateViesVat({commit}, payload) {

        try {

            return await userService.validateViesVat(payload);

        } catch (err) {

            return err;
        }
    },

    /**
     * Login
     * @param commit
     * @param payload
     * @returns {Promise<*>}
     */
    async login({commit}, payload) {

        try {

            commit('setRequestLoading', true);

            const response = await userService.login(payload);

            if (response && response.status === 200) {

                commit('setUser', response.data)
            }

            commit('setRequestLoading', false);

            return response;

        } catch (err) {

            commit('setRequestLoading', false);

            return err;
        }
    },

    /**
     * Logout
     * @param commit
     * @param payload
     * @returns {Promise<*>}
     */
    async logout({commit}, payload) {

        try {

            commit('clearStore');

            return true;

        } catch (err) {

            return false;
        }
    },

    /**
     * Register
     * @param commit
     * @param payload
     * @returns {Promise<*>}
     */
    async register({commit}, payload) {

        try {

            commit('setRequestLoading', true);

            const response = await userService.register(payload);

            commit('setRequestLoading', false);

            return response;

        } catch (err) {

            commit('setRequestLoading', false);

            return err;
        }
    },

    /**
     * Get
     * @param commit
     * @param payload
     * @returns {Promise<*>}
     */
    async get({commit, state}) {

        try {

            commit('setRequestLoading', true);

            const response = await userService.get({_id: state.user._id});

            if (response && response.status === 200) {
                commit('setUser', response.data)
            }
            commit('setRequestLoading', false);

            return response;

        } catch (err) {

            commit('setRequestLoading', false);

            return err;
        }
    },

    /**
     * Update
     * @param commit
     * @param payload
     * @returns {Promise<*>}
     */
    async update({commit}, payload) {

        try {

            commit('setRequestLoading', true);

            const response = await userService.update(payload);

            if (response && response.status === 200) {

                commit('setUser', response.data)
            }

            commit('setRequestLoading', false);

            return response;

        } catch (err) {

            commit('setRequestLoading', false);

            return err;
        }
    },

    /**
     * Confirm registration
     * @param commit
     * @param payload
     * @returns {Promise<*>}
     */
    async confirmRegistration({commit}, payload) {

        try {

            return await userService.confirmRegistration(payload);

        } catch (err) {

            return err;
        }
    },

    /**
     * Send email with instructions for reset password
     * @param commit
     * @param payload
     * @returns {Promise<*>}
     */
    async sendResetPasswordEmail({commit}, payload) {

        try {

            commit('setRequestLoading', true);

            const response = await userService.sendResetPasswordEmail(payload);

            commit('setRequestLoading', false);

            return response;

        } catch (err) {

            commit('setRequestLoading', false);

            return err;
        }
    },

    /**
     * Resets password
     * @param commit
     * @param payload
     * @returns {Promise<*>}
     */
    async resetPassword({commit}, payload) {

        try {

            commit('setRequestLoading', true);

            const response = await userService.setNewPassword(payload);

            commit('setRequestLoading', false);

            return response;

        } catch (err) {

            commit('setRequestLoading', false);

            return err;
        }
    },

    /**
     * Set jwt token
     * @param commit
     * @param payload
     * @returns {Promise<*>}
     */
    async setJwtToken({commit}, payload) {

        try {

            commit('setJwtToken', payload);

        } catch (err) {

            return err;
        }
    },

    /**
     * Get logo
     * @param commit
     * @param dispatch
     * @param payload
     * @returns {Promise<*>}
     */
    async getLogo({commit, dispatch}, payload) {

        try {

            const response = await userService.getLogo(payload);

            commit('setLogos', response.data);

            return response.data;

        } catch (err) {

            return err;
        }
    },

    /**
     * Add logo
     * @param commit
     * @param dispatch
     * @param payload
     * @returns {Promise<*>}
     */
    async addLogo({commit, dispatch}, payload) {

        try {

            commit('setRequestLoading', true);

            const added_logo = await userService.addLogo(payload);

            await dispatch('getLogo');

            commit('setRequestLoading', false);

            return added_logo;

        } catch (err) {

            commit('setRequestLoading', false);

            return err;
        }
    },

    /**
     * Add sub entity
     * @param commit
     * @param dispatch
     * @param payload
     * @returns {Promise<*>}
     */
    async deleteLogo({commit, dispatch}, payload) {

        try {

            commit('setRequestLoading', true);

            const delete_logo = await userService.deleteLogo(payload);

            await dispatch('getLogo');

            commit('setRequestLoading', false);

            return delete_logo;

        } catch (err) {

            commit('setRequestLoading', false);

            return err;
        }
    },

    /**
     * Add certificate
     * @param commit
     * @param dispatch
     * @param payload
     * @returns {Promise<*>}
     */
    async addCertificate({commit, dispatch}, payload) {

        try {

            commit('setRequestLoading', true);

            const added_cert = await userService.addCertificate(payload);

            await dispatch('get', {_id: state.user._id});

            commit('setRequestLoading', false);

            return added_cert;

        } catch (err) {

            commit('setRequestLoading', false);

            return err;
        }
    },

    /**
     * Delete certificate
     * @param dispatch
     * @param payload
     * @returns {Promise<*>}
     */
    async deleteCertificate({dispatch, commit}) {

        try {

            commit('setRequestLoading', true);

            const delete_certificate = await userService.deleteCertificate();

            await dispatch('get', {_id: state.user._id});

            commit('setRequestLoading', false);

            return delete_certificate;

        } catch (err) {

            commit('setRequestLoading', false);

            return err;
        }
    },

};

const getters = {

    user: (state) => {

        return state.user;
    },

    jwtToken: (state) => {

        return state.jwtToken;
    },

    request_loading: (state) => {

        return state.request_loading;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
