import {api} from 'boot/axios'

const moment = require('moment');

const BILL_URL = '/bill';
const BILLS_URL = '/bills';
const BILL_STORNO_URL = BILL_URL + '/storno';
const DOWNLOAD_BILL_URL = BILL_URL + '/download-bill';
const SEND_BILL_TO_EMAIL_URL = BILL_URL + '/send-bill-to-email';

export default {

    /**
     * Get a single bill
     * @param payload
     * @returns {AxiosPromise<any>}
     */
    get(payload = {}) {

        let url = BILL_URL;
        if (payload._id) {
            url += '?_id=' + payload._id;
        }

        return api.get(url);
    },

    /**
     * Get all bills
     * @param payload
     * @returns {AxiosPromise<any>}
     */
    getAll(payload = {}) {

        return api.get(BILLS_URL);
    },

    /**
     * Adds a new bill
     * @param payload
     * @returns {AxiosPromise<any>}
     */
    insert(payload = {}) {

        return api.post(BILL_URL, this.preparePayload(payload));
    },

    /**
     * Storno bill
     * @param payload
     * @returns {AxiosPromise<any>}
     */
    storno(payload = {}) {

        return api.post(BILL_STORNO_URL, this.preparePayload(payload));
    },

    /**
     * Download bill
     * @param payload
     * @returns {AxiosPromise<any>}
     */
    downloadBill(payload = {}) {

        let url = DOWNLOAD_BILL_URL;
        if (payload._id) {
            url += '?_id=' + payload._id;
        }

        return api.get(url);
    },

	sendBillToEmail(payload = {}) {

		return api.post(SEND_BILL_TO_EMAIL_URL, payload);
	},

    /**
     * Prepare payload params
     * @param payload
     * @returns {URLSearchParams}
     */
    preparePayload(payload) {

        payload = Object.assign({}, payload);

        const params = new URLSearchParams();

        if (payload._id) {
            params.append('_id', payload._id);
        }

        if (payload.user) {
            if (payload.user.email) {
                params.append('user_email', payload.user.email);
            }
            if (payload.user.vat_id) {
                params.append('user_vat_id', payload.user.vat_id);
            }
            if (payload.user.company) {
                params.append('user_company', payload.user.company);
            }
            if (payload.user.name) {
                params.append('user_name', payload.user.name);
            }
            if (payload.user.last_name) {
                params.append('user_last_name', payload.user.last_name);
            }
            if (payload.user.street) {
                params.append('user_street', payload.user.street);
            }
            if (payload.user.house_number) {
                params.append('user_house_number', payload.user.house_number);
            }
            if (payload.user.post_number) {
                params.append('user_post_number', payload.user.post_number);
            }
            if (payload.user.city) {
                params.append('user_city', payload.user.city);
            }
            if (payload.user.iban) {
                params.append('user_iban', payload.user.iban);
            }
            if (payload.user.phone_number) {
                params.append('user_phone_number', payload.user.phone_number);
            }
            params.append('user_country', JSON.stringify({"country": "Slovenija", "iso": "SI", "currency": "EUR"}));
        }
        if (payload.logo && payload.logo._id) {
            params.append('logo_id', payload.logo._id);
        }
        if (payload.customer && !payload.no_customer_on_bill) {
            if (payload.customer.email) {
                params.append('client_email', payload.customer.email);
            }
            if (payload.customer.name) {
                params.append('client_name', payload.customer.name);
            }
            if (payload.customer.last_name) {
                params.append('client_last_name', payload.customer.last_name);
            }
            if (payload.customer.company) {
                params.append('client_company', payload.customer.company);
            }
            if (payload.customer.street) {
                params.append('client_street', payload.customer.street);
            }
            if (payload.customer.house_number) {
                params.append('client_house_number', payload.customer.house_number);
            }
            if (payload.customer.post_number) {
                params.append('client_post_number', payload.customer.post_number);
            }
            if (payload.customer.city) {
                params.append('client_city', payload.customer.city);
            }
            if (payload.customer.vat_id) {
                params.append('client_vat_id', payload.customer.vat_id);
            }
            params.append('client_country', JSON.stringify({"country": "Slovenija", "iso": "SI", "currency": "EUR"}));
        }
        if (payload.no_customer_on_bill) {
            params.append('no_customer_on_bill', payload.no_customer_on_bill);
        }
        if (payload.no_payment_method_on_bill) {
            params.append('no_payment_method_on_bill', payload.no_payment_method_on_bill);
        }
        if (payload.payment_method) {
            params.append('payment_method', payload.payment_method.value);
        }

        if (payload.date_of_issue) {
            params.append('date_of_issue', moment(payload.date_of_issue, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'));
        }

        if (payload.date_of_service && !payload.no_date_of_service_on_bill) {
            params.append('date_of_service_start', moment(payload.date_of_service.from, 'DD-MM-YYYY').format('YYYY-MM-DD'));
            params.append('date_of_service_end', moment(payload.date_of_service.to, 'DD-MM-YYYY').format('YYYY-MM-DD'));
            params.append('no_date_of_service_on_bill', false);
        } else {
            params.append('date_of_service_start', null);
            params.append('date_of_service_end', null);
            params.append('no_date_of_service_on_bill', true);
        }


        if (payload.date_expires && !payload.no_date_of_expiration) {
            params.append('date_expires', moment(payload.date_expires, 'DD-MM-YYYY').format('YYYY-MM-DD'));
            params.append('no_date_of_expiration', false);
        } else {
            params.append('date_expires', null);
            params.append('no_date_of_expiration', true);
        }

        if (payload.final_values) {
            if (payload.final_values.total_price) {
                params.append('price', payload.final_values.total_price);
            }
            if (payload.final_values.total_tax) {
                params.append('tax', payload.final_values.total_tax);
            }
            if (payload.final_values.final_price) {
                params.append('final_price', payload.final_values.final_price);
            }
        }

        if (payload.items) {
            params.append('items', JSON.stringify(payload.items))
        }
        if (payload.appendices) {
            params.append('appendices', JSON.stringify(payload.appendices))
        }
        if (payload.fiscally_confirm_bill) {
            params.append('fiscally_confirm_bill', payload.fiscally_confirm_bill)
        }

        return params;
    }
}
