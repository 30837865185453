<template>
	<router-view/>
</template>
<script>

import {defineComponent} from 'vue';

export default defineComponent({
	name: 'App',
})
</script>

<style>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	-webkit-text-fill-color: inherit;
	-webkit-box-shadow: none;
	transition: background-color 300ms ease-in-out 0s;
}
.q-field--outlined .q-field__control:before {
	z-index: 1;
}
</style>
