import { render } from "./App.vue?vue&type=template&id=44254c35"
import script from "./App.vue?vue&type=script&lang=js"
export * from "./App.vue?vue&type=script&lang=js"

import "./App.vue?vue&type=style&index=0&id=44254c35&lang=css"
script.render = render

export default script
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QField});
