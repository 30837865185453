export default {
	settings: 'Nastavitve',
	profile: 'Profil',
	update: 'Posodobi',
    logos: 'Logotipi',
    name: 'Ime',
    image: 'Slika',
    add_logo: 'Dodaj',
    name_of_logo: 'Ime logotipa',
    add_new_logo: 'Dodaj nov logotip',
    error_adding_logo: 'Napaka pri dodajanju novega logotipa',
    success_adding_logo: 'Uspešno ste dodali nov logotip',
    delete_logo: 'Uspešno ste odstranili logotip',
    logo_suggestion: 'Priporočena velikost logotipa je 300px x 100px, max 25kb',
    certs: 'Certifikat',
    add_certificate: 'Dodaj',
    error_adding_certificate: 'Napaka pri dodajanju novega certifikata',
    success_adding_certificate: 'Uspešno ste dodali nov certifikat.',
    delete_certificate: 'Uspešno ste odstranili certifikat.',
    add_new_certificate: 'Dodaj certifikat.',
    certificate_password: 'Geslo certifikata.',
    bills: 'Račun',
    initial_bill_number: 'Številka prvega računa',
    initial_bill_number_explanation: "Če ste se nam pridružili sredi leta in ste že izdali nekaj računov, prosimo, da vnesete številko za naslednji račun, ki si jo želite. Vsi naslednji računi bodo številčeni v obliki 'leto-številka računa', na primer '2024-01'.",
    error_updating_bill_settings: "Napaka pri posodobitvi nastavitev računov",
    success_updating_bill_settings: "Nastavitve so posodoboljene.",
    business_premise_id: "Poslovni prostor",
    device_id: "Elektronska naprava",
    payment_method_options: "Način plačila",
    customers_option: "Izbira strank",
    options_for_fiscally_confirming_bills: "Davčno potrjevanje računov",
    options_always: "Vedno",
    options_never: "Nikoli",
    options_optionally_true: "Na izbiro (privzeto: izbrano)",
    options_optionally_false: "Na izbiro (privzeto: neizbrano)",
    options_optionally: "Na izbiro",
    options_date_of_service: "Datum storitve",
    options_date_of_expiration: "Datum zapadlosti",
    options_optionally_true_two: "Na izbiro (privzeto: izpiši)",
    options_optionally_false_two: "Na izbiro (privzeto: ne izpiši)",
    fiscal_verification_not_possible: "Za izvedbo davčnega potrjevanja računov je potrebno namestiti vaš certifikat.",
    are_you_sure: "Ali ste prepričani?",
    you_are_going_to_delete: "Odstranili boste logotip. Ali ste prepričani?",
    you_are_going_to_delete_certificate: "Ali ste prepričani, da želite odstraniti certifikat?",
}
