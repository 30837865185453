export default {
	settings: 'Settings',
	profile: 'Profile',
	update: 'Update',
	logos: 'Logos',
    name: 'Name',
    image: 'Image',
	add_logo: 'Add logo',
    name_of_logo: 'Logo name',
    add_new_logo: 'Add new logo',
    error_adding_logo: 'Error when adding new logo',
    success_adding_logo: 'You have successfully added new logo',
    delete_logo: 'You have successfully deleted logo',
    logo_suggestion: 'Suggested upload image size is 300px x 100px, max 25kb',
    certs: 'Certificate',
    add_certificate: 'Add certificate',
    error_adding_certificate: 'Error when adding new certificate',
    success_adding_certificate: 'You have successfully uploaded your certificate.',
    delete_certificate: 'You have successfully deleted certificate.',
    add_new_certificate: 'Add new certificate.',
    certificate_password: 'Certificate password.',
    bills: 'Bill settings',
    initial_bill_number: 'Start counting bills',
    initial_bill_number_explanation: "If you joined us midway through the year and have already issued some invoices, please enter the number for the next invoice you'd like. All subsequent invoices will be numbered in the format 'year-invoice number', for example '2024-01'.",
    error_updating_bill_settings: "There was an error updating your bill settings",
    success_updating_bill_settings: "You have successfully updated your bill settings.",
    business_premise_id: "Business premise ID",
    device_id: "Device ID",
    payment_method_options: "Payment method options",
    customers_option: "Customers",
    options_for_fiscally_confirming_bills: "Fiscal confirmation of bills",
    options_always: "Always",
    options_never: "Never",
    options_optionally_true: "Optionally (default: selected)",
    options_optionally_false: "Optionally (default: not selected)",
    options_optionally: "Optionally",
    options_date_of_service: "Date of service",
    options_date_of_expiration: "Date of expiration",
    fiscal_verification_not_possible: "To perform tax invoice verification, it is necessary to install your certificate.",
    are_you_sure: "Are u sure?",
    you_are_going_to_delete: "You are going to delete one of your logo",
    you_are_going_to_delete_certificate: "You are going to delete certificate! Are u sure?",
}
