import login from './login'
import register from './register'
import complete_registration from "./completeRegistration"
import confirm_registration from "./confirmRegistration"
import reset_password_email from './resetPasswordEmail'
import reset_password_new from './resetPasswordNew'
import customers from './customers'
import menu from './menu'
import settings from './settings'
import bills from './bills'
import skus from './skus'
import statistics from './statistics'
import exports from './exports'

export default {
	failed: 'Napaka',
	success: 'Action was successful',
    header_my_bills: 'Moji računi',
    select_language: 'Jezik',
    button_loading: 'Obdelujem...',
    log_out: 'Odjava',
    field_required: 'Obvezen podatek.',
    field_must_be_greater_then_zero: 'Število mora biti večje od 0',
	please_input_valid_email: 'Prosimo vpišite veljaven e-poštni naslov.',
    only_whole_numbers_allowed: 'Samo cela števila!',
	not_registered_yet: 'Še nimate računa? Registrirajte se!',
    forgot_password: 'Ste pozabili geslo? Ponastavite ga tukaj.',
	already_have_account: 'Že imate račun? Prijavite se tukaj.',
	password: 'Geslo*',
	repeat_password: 'Ponovi geslo*',
    street: 'Ulica',
	house_number: 'Hišna št.',
    post_number: 'Poštna št.',
	city: 'Mesto',
	country: 'Država',
    decimal_hint: 'Decmalna števila ločimo s piko. Primer: 3.14',
	email: 'E-pošta',
    vat_id: 'Davčna št.',
    passwords_dont_match: 'Gesli se ne ujemata',
    phone_number: 'Mobilna št.',
	validate_password: 'Geslo mora biti dolgo med 7 in 35 znakov. Vsebovati mora vsaj eno številko ter malo in veliko črko.',
    example_phone_number: 'Primer: (+386) 41-123-456',
    field_must_have_four: 'Poštna številka mora vsebovati 4 števila',
	default: 'Privzeto',
	add: 'Dodaj',
	remove: 'Odstrani',
	update: 'Posodobi',
	delete: 'Zbriši',
    cancel: 'Prekliči',
    january: "Januar",
    february: "Februar",
    march: "Marec",
    april: "April",
    may: "Maj",
    june: "Junij",
    july: "Julij",
    august: "Avgust",
    september: "September",
    october: "Oktober",
    november: "November",
    december: "December",
    gross: 'Bruto',
    net: 'Neto',
    tax: 'Davek',
    year: 'Leto',
    month: 'Mesec',

	login,
	register,
    complete_registration,
    confirm_registration,
    reset_password_email,
    reset_password_new,
	customers,
	menu,
	settings,
	bills,
    skus,
    statistics,
    exports,
}
