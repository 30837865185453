export default {
	register: 'Register',
	company: 'Company name',
	name: 'Name',
    last_name: 'Last name',
	iban: 'IBAN',
    registration_number: 'Register number',
    company_type: 'Company type',
	veisVatNotValid: 'Vat ID is not valid.',
    register_button: 'Register',
	update_button: 'Update',
	example_iban: 'Ex.: SI56 0649 6783 3657 647',
	example_register_number: 'Ex.: 1232409000',
	register_number_length: 'Matična številka mora biti točno 10 števil.',
	registration_success: 'You have successfully registered!',
    update_success: 'You have successfully update user',
	taxable: 'Vat registered',
    a_typical_taxable: 'Atypical vat registered',
	user_already_exists_please_login_or_reset_password: 'User already exists. Please log in or reset password.',
}
