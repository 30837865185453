import {api} from 'boot/axios'

const CUSTOMER_URL = '/customer';
const CUSTOMERS_URL = '/customers';
const UPDATE_DEFAULT_URL = CUSTOMER_URL + '/update-default';

export default {


    /**
     * Get all customers
     * @returns {AxiosPromise<any>}
     */
    getAll() {

        return api.get(CUSTOMERS_URL);
    },

	/**
	 * Get one
	 * @param payload
	 * @returns {AxiosPromise<any>}
	 */
	get(payload = {}) {

		let url = CUSTOMER_URL;
		if (payload._id) {
			url += '?_id=' + payload._id;
		}

		return api.get(url);
	},

	/**
	 * Adds new customer
	 * @param payload
	 * @returns {AxiosPromise<any>}
	 */
	insert(payload = {}) {

		return api.post(CUSTOMER_URL, this.preparePayload(payload));
	},

	/**
	 * Updates customer data
	 * @param payload
	 * @returns {AxiosPromise<any>}
	 */
	update(payload = {}) {

		return api.put(CUSTOMER_URL, this.preparePayload(payload));
	},

	/**
	 * Updates default customer
	 * @param payload
	 * @returns {AxiosPromise<any>}
	 */
	updateDefault(payload = {}) {

        payload.default_selected = !payload.default_selected;
		return api.put(UPDATE_DEFAULT_URL, payload);
	},

	/**
	 * Delete customer
	 * @param payload
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	delete(payload = {}) {

		return api.delete(CUSTOMER_URL + '/' + payload._id);
	},

	/**
	 * Prepare payload params
	 * @param payload
	 * @returns {URLSearchParams}
	 */
	preparePayload(payload) {

		const params = new URLSearchParams();

		if (payload.value._id) {
			params.append('_id', payload.value._id);
		}
		if (payload.value.name) {
			params.append('name', payload.value.name);
		}
		if (payload.value.last_name) {
			params.append('last_name', payload.value.last_name);
		}
		if (payload.value.company) {
			params.append('company', payload.value.company);
		}
		if (payload.value.street) {
			params.append('street', payload.value.street);
		}
		if (payload.value.house_number) {
			params.append('house_number', payload.value.house_number);
		}
		if (payload.value.post_number) {
			params.append('post_number', payload.value.post_number);
		}
		if (payload.value.city) {
			params.append('city', payload.value.city);
		}
		if (payload.value.vat_id) {
			params.append('vat_id', payload.value.vat_id);
		}
		if (payload.value.email) {
			params.append('email', payload.value.email);
		}
		if (payload.value.phone_number) {
			params.append('phone_number', payload.value.phone_number);
		}
		if (typeof payload.value.default_selected !== 'undefined') {
			params.append('default_selected', payload.value.default_selected);
		}

		return params;
	}
}
