import skuService from '../../api-service/sku.service'
import {Loading} from 'quasar'

const state = {

    request_loading: false,
    skus: [],
};

const mutations = {

    setSku(state, payload) {

        state.skus = payload;
    },

    setRequestLoading(state, payload) {

        payload ? Loading.show() : Loading.hide();
        state.request_loading = payload;
    },

    removeItem(state, itemId) {

        state.skus = state.skus.filter(item => item._id !== itemId);
    }

};

const actions = {

    /**
     * Get sku
     * @param commit
     * @returns {Promise<*>}
     */
    async skus({commit}) {

        try {

            commit('setRequestLoading', true);
            commit('setSku', [])

            const response = await skuService.getAll();

            if (response && response.status === 200) {

                commit('setSku', response.data)
            }

            commit('setRequestLoading', false);

            return response;

        } catch (err) {

            commit('setRequestLoading', false);

            return err;
        }
    },

    /**
     * Get sku
     * @param commit
     * @param payload
     * @returns {Promise<*>}
     */
    async get({commit}, payload) {

        try {

            commit('setRequestLoading', true);

            const response = await skuService.get(payload);

            commit('setRequestLoading', false);

            return response;

        } catch (err) {

            commit('setRequestLoading', false);

            return err;
        }
    },

    /**
     * Add new sku
     * @param commit
     * @param payload
     * @returns {Promise<*>}
     */
    async add({commit}, payload) {

        try {

            commit('setRequestLoading', true);

            const response = await skuService.insert(payload);

            commit('setRequestLoading', false);

            return response;

        } catch (err) {

            commit('setRequestLoading', false);

            return err;
        }
    },

    /**
     * Update service
     * @param commit
     * @param payload
     * @returns {Promise<*>}
     */
    async update({commit}, payload) {

        try {

            commit('setRequestLoading', true);

            const response = await skuService.update(payload);

            commit('setRequestLoading', false);

            return response;

        } catch (err) {

            commit('setRequestLoading', false);

            return err;
        }
    },

    /**
     * Delete sku
     * @param commit
     * @param dispatch
     * @param payload
     * @returns {Promise<*>}
     */
    async delete({commit, dispatch}, payload) {

        try {

            commit('setRequestLoading', true);

            const response = await skuService.delete(payload);

            if (response && (response.status === 200 || response.status === 204)) {
                commit('removeItem', payload.id)
            }

            commit('setRequestLoading', false);

            return response;

        } catch (err) {

            commit('setRequestLoading', false);

            return err;
        }
    },

};

const getters = {

    skus: (state) => {

        return state.skus;
    },

    request_loading: (state) => {

        return state.request_loading;
    },

};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
