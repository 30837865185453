export default {
    my_customers: 'My customers',
	add_customer: 'Add new customer',
	update_customer: 'Update customer',
    name: 'Name',
    last_name: 'Last name',
    company: 'Company name',
	add: 'Add',
	edit: 'Edit',
	update: 'Update',
	delete: 'Delete',
    are_you_sure: 'Are you sure?',
    you_are_going_to_delete: 'Are you sure, you wish to delete your customer',
	cancel: 'Cancel',
	successfully_deleted: 'Customer was successfully deleted.',
	success_updated: 'Success! Customer was successfully updated.',
    success_added: 'Success! Customer was successfully added.',
    please_correct_errors: 'Please correct all errors.',
}
