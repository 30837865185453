import {store} from 'quasar/wrappers'
import {createStore} from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import user from './modules/user-store'
import country from './modules/country-store'
import company_type from './modules/company-type-store'
import bill from './modules/bill-store'
import customer from './modules/customer-store'
import sku from './modules/sku-store'
import settings from './modules/settings-store'

export default store((/* { ssrContext } */) => {

	return createStore({

		modules: {
			country,
			user,
			company_type,
			bill,
			customer,
			settings,
            sku,
		},
		plugins: [createPersistedState()],

		// enable strict mode (adds overhead!)
		// for dev mode and --debug builds only
		strict: process.env.DEBUGGING,
	})
})
