import {api} from 'boot/axios'

const COUNTRY_URL = '/country';

export default {

	/**
	 * Get
	 * @returns {AxiosPromise<any>}
	 */
	get() {

		return api.get(COUNTRY_URL);
	},
}
