import customerService from '../../api-service/customer.service'
import {Loading} from 'quasar'


const state = {

	tax_values: [{value: 22, label: '22%'}, {value: 9.5, label: '9.5%'}, {value: 0, label: '0%'}],
    request_loading: false,
};

const mutations = {

	setRequestLoading(state, payload) {

		payload ? Loading.show() : Loading.hide();
		state.request_loading = payload;
	},

	clearStore(state) {
		state.request_loading = false;
	}

};

const actions = {


	async customers({commit}, payload) {

		try {

			commit('setRequestLoading', true);

			const response = await customerService.get(payload);

			if (response && response.status === 200) {

				commit('setCustomers', response.data)
			}

			commit('setRequestLoading', false);

			return response;

		} catch (err) {

			commit('setRequestLoading', false);

			return err;
		}
	}

};

const getters = {

    request_loading: (state) => {

		return state.request_loading;
	},

	tax_values: (state) => {

		return state.tax_values;
	},

};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
