import {api} from 'boot/axios'

const SKU_URL = '/sku';

export default {

	/**
	 * Get sku
	 * @param payload
	 * @returns {AxiosPromise<any>}
	 */
	get(payload = {}) {

		let url = SKU_URL + '?_id=' + payload.id

		return api.get(url);
	},

	/**
	 * Get all skus
	 * @param payload
	 * @returns {AxiosPromise<any>}
	 */
	getAll() {

		let url = SKU_URL + 's';

		return api.get(url);
	},

	/**
	 * Search
	 * @param payload
	 * @returns {AxiosPromise<any>}
	 */
	search(payload) {

		let url = SKU_URL + '/search?term=' + encodeURIComponent(payload.term);

		return api.get(url);
	},

	/**
	 * Adds new sku
	 * @param payload
	 * @returns {AxiosPromise<any>}
	 */
	insert(payload = {}) {

		return api.post(SKU_URL, this.preparePayload(payload));
	},

	/**
	 * Updates sku
	 * @param payload
	 * @returns {AxiosPromise<any>}
	 */
	update(payload = {}) {

		return api.put(SKU_URL, this.preparePayload(payload));
	},

	/**
	 * Delete sku
	 * @param payload
	 * @returns {Promise<AxiosResponse<any>>}
	 */
	delete(payload = {}) {

		return api.delete(SKU_URL + '/' + payload.id);
	},

	/**
	 * Prepare payload params
	 * @param payload
	 * @returns {URLSearchParams}
	 */
	preparePayload(payload) {

		const params = new URLSearchParams();

        Object.entries(payload.value).forEach(entry => {
            params.append(entry[0], entry[1])
        });

		return params;
	}
}
