export default {
    logout: 'LOGOUT',
	bills: 'BILLS',
	customers: 'CUSTOMERS',
    skus: 'PRODUCTS',
	settings: 'SETTINGS',
    statistics: "STATISTICS",
    exports: "EXPORTS",
    exports_products_taxes: "EXPORT BY TAX"
}
