import {api} from 'boot/axios'

const USER_URL = '/user';
const LOGIN = USER_URL + '/login';
const REGISTER = USER_URL + '/register';
const CONFIRM = USER_URL + '/confirm-registration';
const VALIDATE_VIES_VAR = USER_URL + '/validate-vies-vat';
const RESET_PASSWORD_EMAIL = USER_URL + '/reset-password-email';
const SET_NEW_PASSWORD = USER_URL + '/set-new-password';
const LOGO_URL = USER_URL + '/logo';
const CERTIFICATE_URL = USER_URL + '/certificate';

export default {

	/**
	 * Login
	 * @param payload
	 * @returns {AxiosPromise<any>}
	 */
	login(payload = {}) {

		const params = new URLSearchParams();
		params.append('email', payload.value.email);
		params.append('password', payload.value.password);

		return api.post(LOGIN, params);
	},

    /**
     * Get current user
     * @param payload
     * @returns {Promise<AxiosResponse<any>>}
     */
    get(payload = {}) {

        let url = USER_URL;
        if (payload._id) {
            url += '?_id=' + payload._id;
        }

        return api.get(url);
    },

	/**
	 * Register
	 * @param payload
	 * @returns {AxiosPromise<any>}
	 */
	register(payload = {}) {

		const params = new URLSearchParams();

        Object.entries(payload.value).forEach(entry => {
            params.append(entry[0], entry[1])
        });

        return api.post(REGISTER, params);
	},

	/**
	 * Update
	 * @param payload
	 * @returns {AxiosPromise<any>}
	 */
	update(payload = {}) {

		const params = new URLSearchParams();

        Object.entries(payload.value).forEach(entry => {
            params.append(entry[0], entry[1])
        });

        return api.put(USER_URL, params);
	},

	/**
	 * Confirm registration
	 * @param payload
	 * @returns {AxiosPromise<any>}
	 */
	confirmRegistration(payload = {}) {

		const params = new URLSearchParams();
        Object.entries(payload).forEach(entry => {
            params.append(entry[0], entry[1])
        });

		return api.post(CONFIRM, params);
	},

	/**
	 * Send reset password email
	 * @param payload
	 * @returns {AxiosPromise<any>}
	 */
	sendResetPasswordEmail(payload = {}) {

		const params = new URLSearchParams();
		params.append('email', payload.email);

		return api.post(RESET_PASSWORD_EMAIL, params);
	},

	/**
	 * Sets new password when reset password is requested
	 * @param payload
	 * @returns {AxiosPromise<any>}
	 */
	setNewPassword(payload = {}) {

		const params = new URLSearchParams();
		params.append('email', payload.email);
		params.append('password_reset_token', payload.password_reset_token);
		params.append('password', payload.password);

		return api.put(SET_NEW_PASSWORD, params);
	},

    /**
     * Get logo
     * @param payload
     * @returns {Promise<AxiosResponse<any>>}
     */
    getLogo(payload = {}) {

        return api.get(LOGO_URL);
    },

    /**
     * Add Sub entity
     * @param payload
     * @returns {Promise<AxiosResponse<any>>}
     */
    addLogo(payload = {}) {

        const formData = new FormData();
        formData.append("logo_name", payload.logo_name);
        formData.append("image", payload.image);

        return api.post(LOGO_URL, formData);
    },

    /**
     * Delete logo
     * @param payload
     * @returns {Promise<AxiosResponse<any>>}
     */
    deleteLogo(payload = {}) {

        return api.delete(LOGO_URL + '/' + payload._id);
    },

    /**
     * Validate vies vat
     * @param payload
     * @returns {Promise<AxiosResponse<any>>}
     */
    validateViesVat(payload = {}) {

        let url = VALIDATE_VIES_VAR;
        if (payload.vat_id) {
            url += '?vat_id=' + payload.vat_id;
        }

        return api.get(url);
    },

    /**
     * Add certificate
     * @param payload
     * @returns {Promise<AxiosResponse<any>>}
     */
    addCertificate(payload = {}) {

        const formData = new FormData();
        formData.append("certificate", payload.certificate);
        formData.append("password", payload.password);

        return api.post(CERTIFICATE_URL, formData);
    },

    /**
     * Delete certificate
     * @returns {Promise<AxiosResponse<any>>}
     */
    deleteCertificate() {

        return api.delete(CERTIFICATE_URL);
    },
}
