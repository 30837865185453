import customerService from '../../api-service/customer.service'
import {Loading} from 'quasar'

const state = {

    request_loading: false,
    customer: {},
    customers: [],
};

const mutations = {

    setCustomer(state, payload) {
        state.customer = payload;
    },

    setCustomers(state, payload) {
        state.customers = payload;
    },

    updateDefaultCustomer(state, payload) {
        for (let i = 0; i < state.customers.length; i++) {
            state.customers[i].default_selected = false;
            if (state.customers[i]._id === payload._id) {
                if (payload.default_selected) {
                    state.customers[i].default_selected = payload.default_selected;
                }
            }
        }
    },

    setRequestLoading(state, payload) {

        payload ? Loading.show() : Loading.hide();
        state.request_loading = payload;
    },

    clearStore(state) {
        state.request_loading = false;
        state.customers = [];
    }

};

const actions = {

    /**
     * Get customer
     * @param commit
     * @param payload
     * @returns {Promise<*>}
     */
    async customer({commit}, payload) {

        try {

            commit('setRequestLoading', true);

            const response = await customerService.get(payload);

            if (response && response.status === 200) {

                commit('setCustomer', response.data)
            }

            commit('setRequestLoading', false);

            return response;

        } catch (err) {

            commit('setRequestLoading', false);

            return err;
        }
    },

    /**
     * Get customers
     * @param commit
     * @param payload
     * @returns {Promise<*>}
     */
    async customers({commit}) {

        try {

            const response = await customerService.getAll();

            if (response && response.status === 200) {

                commit('setCustomers', response.data)
            }
            if (response && response.status === 204) {

                commit('setCustomers', [])
            }

            commit('setRequestLoading', false);

            return response;

        } catch (err) {

            commit('setRequestLoading', false);

            return err;
        }
    },

    /**
     * Update default customer
     * @param commit
     * @param payload
     * @returns {Promise<AxiosPromise<*>|*>}
     */
    async updateDefault({commit}, payload) {

        try {

            commit('setRequestLoading', true);
            const response = await customerService.updateDefault(payload);

            if (response && response.status === 200) {
                commit('updateDefaultCustomer', response.data)
            }

            commit('setRequestLoading', false);

            return response;

        } catch (err) {

            commit('setRequestLoading', false);

            return err;
        }
    },

    /**
     * Get customer
     * @param commit
     * @param payload
     * @returns {Promise<*>}
     */
    async get({commit}, payload) {

        try {

            commit('setRequestLoading', true);

            const response = await customerService.get(payload);

            commit('setRequestLoading', false);

            return response;

        } catch (err) {

            commit('setRequestLoading', false);

            return err;
        }
    },

    /**
     * Add new customer
     * @param commit
     * @param payload
     * @returns {Promise<*>}
     */
    async add({commit}, payload) {

        try {

            commit('setRequestLoading', true);

            const response = await customerService.insert(payload);

            commit('setRequestLoading', false);

            return response;

        } catch (err) {

            commit('setRequestLoading', false);

            return err;
        }
    },

    /**
     * Update customer
     * @param commit
     * @param payload
     * @returns {Promise<*>}
     */
    async update({commit}, payload) {

        try {

            commit('setRequestLoading', true);

            const response = await customerService.update(payload);

            commit('setRequestLoading', false);

            return response;

        } catch (err) {

            commit('setRequestLoading', false);

            return err;
        }
    },

    /**
     * delete customer
     * @param commit
     * @param dispatch
     * @param payload
     * @returns {Promise<*>}
     */
    async delete({commit, dispatch}, payload) {

        try {

            commit('setRequestLoading', true);

            const response = await customerService.delete(payload);

            commit('setRequestLoading', false);

            return response;

        } catch (err) {

            commit('setRequestLoading', false);

            return err;
        }
    },

};

const getters = {

    customers: (state) => {

        return state.customers;
    },

    request_loading: (state) => {

        return state.request_loading;
    },

};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
