import { boot } from 'quasar/wrappers'
import { Notify } from 'quasar';

const notifyError = (errors) => {

	let message = '';
	if (errors.data && errors.data.messages && errors.data.messages.error) {
		message += errors.data.messages.error + " ";
	}
	if (errors.data && errors.data.messages && errors.data.messages.errors && Object.keys(errors.data.messages.errors).length > 0) {
		Object.values(errors.data.messages.errors).forEach(value => {
			message += value + " ";
		});
	}
	if (errors.message) {
		message += errors.message + " ";
	}
	if (typeof errors === 'string') {
		message = errors;
	}

	Notify.create({
		message,
		color: 'negative',
		actions: [
			{ label: 'Dismiss', color: 'white', handler: () => { /* ... */ } },
		],
	});
};

const notifySuccess = (message) => {

	Notify.create({
		message,
		color: 'positive',
	});
};

export default boot(({ app }) => {

	app.config.globalProperties.$notifySuccess = notifySuccess
	app.config.globalProperties.$notifyError = notifyError

	app.provide('notifySuccess', notifySuccess);
	app.provide('notifyError', notifyError);

})

export { notifySuccess, notifyError }

