<template>
	<q-btn unelevated
		   :color="color"
		   :size="size"
		   :class="classes"
		   :label="loading ? $t('button_loading') : label"
		   :disable="loading || disabled"
		   :type="type"
		   :icon="loading ? 'fas fa-spinner fa-pulse' : undefined"
	/>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
	props: {
		color: {
			type: String,
			required: false,
			default: 'light-green-7'
		},
		size: {
			type: String,
			required: false,
			default: 'lg'
		},
		label: {
			type: String,
			required: true
		},
		loading: {
			type: Boolean,
			required: false
		},
		classes: {
			required: false,
			default: 'row full-width'
		},
		disabled: {
			required: false,
			type: Boolean,
			default: false
		},
		type: {
			type: String,
			default: 'submit'
		}
	}
})
</script>
