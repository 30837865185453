export default {
	logout: 'ODJAVA',
	bills: 'RAČUNI',
	customers: 'STRANKE',
    skus: 'PRODUKTI',
	settings: 'NASTAVITVE',
    statistics: "STATISTIKA",
    exports: "IZVOZ",
    exports_products_taxes: "PO DAVČNI OSNOVI"
}
