export default {
    skus: 'Skus',
    update_sku: 'Update product',
	add: 'Add',
	edit: 'Edit',
	update: 'Edit',
	delete: 'Delete',
	final_price: "Final price",
	qty: "Quantity",
	discount: "Discount",
    are_you_sure: 'Are you sure?',
    you_are_going_to_delete: 'Are you sure, you wish to delete your product',
	cancel: 'Cancel',
    successfully_deleted: 'Product was successfully deleted.',
    success_updated: 'Success! Product was successfully updated.',
    success_added: 'Success! Product was successfully added.',
    please_correct_errors: 'Please correct all errors.',
    title: 'Title',
    net: 'Net',
    gross: 'Gross',
	unit: 'Unit (item, hour, etc...)',
    net_price: 'Price per unit (net)',
    gross_price: 'Price per unit (gross)',
    tax: 'Tax',
	add_new_product: 'Add new service',
    gross_price_notification: 'You are currently not marked as a taxpayer (tax is not calculated), which means that only the net price is considered when issuing invoices. If you wish to adjust this setting, please visit the "Settings" section, then select the "Profile" option in the menu and check the "Taxpayer" box.',
}
