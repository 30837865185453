const state = {

	company_types: ["Navaden / Polni s.p.", "Popoldanski s.p.", "Društvo"],
};

const mutations = {

};

const actions = {

};

const getters = {

	company_types: (state) => {

		return state.company_types;
	},
};

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters
}
